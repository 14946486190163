<template>
  <el-card class="book-header">
    <div class="header">
      <input ref="file" type="file" class="file-input" :accept="actImg === 0 ? `image/jpeg, image/png` : `image/tiff, application/postscript`" :value="fileVal" @change="fileChange($event, imgComponents[0])">
      <input ref="pageNumFile" type="file" class="file-input" :accept="actPageNumImg === 0 ? `image/jpeg, image/png` : `image/tiff, application/postscript`" :value="fileVal" @change="pageNumFileChange($event, pagenumComponents[0])">
      <tabs v-model="tabIndex" :tabs="tabs" class="inline-tab" />
      <div class="check-box">
        <el-checkbox-group :value="checkList" @input="changeCheckList">
          <el-checkbox label="页脚分左右页显示"></el-checkbox>
        </el-checkbox-group>
      </div>
    </div>
    <div class="style-lib" :class="{ closed }">
      <div class="toggle" @click="closed=!closed">页脚版式 <i class="el-icon-caret-top"></i></div>
      <div class="condition-box">
        <div class="left">
          <div class="format-item" v-for="item in formatTypeList" :key="item.value"
            :class="{'act-item': condition.formatType===item.value}"
            @click="switchFormat(item.value)">{{item.label}}</div>
        </div>
        <div class="right">
          <i class="el-icon-search"></i>
          <input type="text" placeholder="输入关键字" v-model="condition.search">
        </div>
      </div>
      <el-tabs class="style-lib-tabs" v-model="condition.formatModel">
        <el-tab-pane v-for="(item, index) in formatList" :key="item.label + index" :name="item.value">
          <span slot="label" class="tab-item">
            <img :src="item.img" alt="" class="tab-item-img">
            <span class="tab-item-text">{{item.label}}</span>
          </span>
          <div class="style-box">
            <div class="item" v-for="(child, i) in formatDataList" :key="i + child.data.name" :class="{'act-item': actFormat.data.name===child.data.name}">
              <el-image :src="child.data.img" alt="" class="img" fit="cover" />
              <div class="text-box">
                <i class="el-icon-success" v-if="actFormat.data.name===child.data.name"></i>
                <span>{{child.data.name}}</span>
              </div>
              <div class="button-box">
                <el-button size="mini" class="button" @click="selectFormat(child)">套用</el-button>
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="check-box">
      <el-checkbox-group :value="component.fbd.enable_fbd" @input="changeEnabledFbd">
        <el-checkbox label="是否使用方正代码进行配置"></el-checkbox>
      </el-checkbox-group>
    </div>
    <div v-if="!component.fbd.enable_fbd" class="preview">
      <el-popover
        placement="bottom"
        width="400"
        trigger="hover">
        <div>
          <span>透明度</span>
          <el-slider v-model="compareStyle.opacity" :step="0.1" show-stops :max="1" size="mini" />
        </div>
        <div>
          <el-button @click="$refs.compareFile.click()" type="primary" size="mini">{{ comparePic.url ? '更换比对图' : '添加对比图' }}</el-button>
          <el-button @click="removeComparePic" type="primary" v-if="comparePic.url" size="mini">删除对比图</el-button>
        </div>
        <input ref="compareFile" type="file" accept="image/*" class="file-input" @change="addComparePic" />
        <el-button size="mini" style="padding: 0" class="btn style-tool-button--text" slot="reference"  icon="el-icon-plus">对比图</el-button>
      </el-popover>
      <div class="page" ref="page" :style="pageStyle">
        <div class="page-core">
          <div class="page-core-left" :style="pageMarginLeftStyle"></div>
          <div class="page-core-center">
            <div>版心</div>
          </div>
          <div class="page-core-right" :style="pageMarginRightStyle"></div>
        </div>
         <div class="page-margin" :style="pageMarginStyle">
          <div class="margin-left" :style="pageMarginLeftStyle"></div>
          <div class="margin-center">
            <div class="margin-page-num" v-if="pagenumComponents.length" :style="pagenumStyle">
              <img :src="pagenumComponents[0].content.url" alt="" v-if="pagenumComponents[0].content.url" :style="{width: pagenumComponents[0].img_size.width, height: pagenumComponents[0].img_size.height}">
              <div v-if="pagenumComponents[0].content.url" :style="{ height: lineSpacing }"></div>
              <div :style="{marginTop: `${-parseFloat(pagenumComponents[0].content.offset) || 0}mm`}">{{pagenumComponents[0].content.text.replace('@PN', '1')}}</div>
            </div>
            <div class="margin-inner" :style="marginContainerStyle">
              <div class="margin-img-box" v-if="imgComponents && imgComponents.length" :style="imgStyle">
                <div class="dot" v-if="imgComponents[0].content.url"></div>
                <img
                  :src="imgComponents[0].content.url + '?x-oss-process=image/format,jpg'"
                  alt=""
                  style="width: 100%; height: 100%; display: block;"
                  v-if="imgComponents[0].content.url">
                <div v-else class="img-background">
                  <div class="add-img-btn" @dblclick="uploadImage">双击此处添加图片</div>
                </div>
              </div>
              <div class="margin-text-box" :style="textContainerStyle" v-if="textComponents.length">
                <div class="margin-text">
                   <template v-for="item in textComponents.filter(item => !item.content.align || item.content.align === 'left')">
                    <span :key="item.node_id" :data-node_id="item.node_id" :style="calcStyle(item)">{{item.content.text || '双击此处添加文字'}}</span>
                  </template>
                </div>
                <template v-for="item in textComponents.filter(item => item.content.align && item.content.align !== 'left')">
                  <div :key="item.node_id" :data-node_id="item.node_id" class="margin-text" :style="calcStyle(item)">{{item.content.text || '双击此处添加文字'}}</div>
                </template>
              </div>
            </div>
          </div>
          <div class="margin-right" :style="pageMarginRightStyle"></div>
        </div>
        <div class="guide v-guide" :style="leftGuideStyle"></div>
        <div class="guide v-guide" :style="rightGuideStyle"></div>
        <div class="guide h-guide" :style="bottomGuideStyle"></div>
      </div>
    </div>
    <div v-if="!component.fbd.enable_fbd" class="config">
      <el-timeline>
        <el-timeline-item hide-timestamp :icon="actDot.includes('0') ? 'el-icon-arrow-down' : 'el-icon-arrow-right'" :type="actDot.includes('0') ? 'primary' : 'info'" size="large">
          <span class="timeline-title" @click="handleDot('0')">
            <span>整体效果</span>
            <el-button class="style-tool-button--text m-l-20" @click.stop="setComponent" icon="el-icon-plus">生成版式</el-button>
          </span>
          <el-row v-if="actDot.includes('0')" class="config-item">
            <el-form :inline="true" class="demo-form-inline" size="mini" label-width="140px">
              <el-form-item label="页脚距版心距离：">
                <unit-input v-model="component.relative_margin"  unit="mm"/>
                <span>&nbsp;mm</span>
              </el-form-item>
              <el-form-item label="页脚与订口距离：">
                <unit-input v-model="component.margin_before"  unit="mm"/>
                <span>&nbsp;mm</span>
              </el-form-item>
              <el-form-item label="文字相对位移：">
                <unit-input v-model="component.offset"  unit="mm"/>
                <span>&nbsp;mm</span>
              </el-form-item>
            </el-form>
          </el-row>
        </el-timeline-item>
        <el-timeline-item hide-timestamp :icon="actDot.includes('1') ? 'el-icon-arrow-down' : 'el-icon-arrow-right'" :type="actDot.includes('1') ? 'primary' : 'info'" size="large">
          <span class="timeline-title" @click="handleDot('1')">页脚文字</span>
          <el-button class="style-tool-button--text m-l-20" icon="el-icon-plus" @click="addText">新增</el-button>
          <el-row v-if="actDot.includes('1')" class="config-item">
            <div class="text-item" v-for="(item, index) in textComponents" :key="item.node_id">
              <div class="header">
                <div>页脚文字{{String.fromCharCode(index + 9312)}}</div>
                <el-button class="style-tool-button--text" icon="el-icon-delete" @click="delItem(item)">删除</el-button>
              </div>
              <div class="text-body">
                <el-form :inline="true" size="mini" label-position="right" label-width="100px">
                  <el-form-item label="文字类型：">
                    <el-select :value="checkText(item.content.text)" @input="changeTextType($event, item.content)" class="style-tool-select" :popper-append-to-body="false">
                      <el-option label="文本" value="text" />
                      <el-option label="文章标题" value="title" />
                    </el-select>
                  </el-form-item>
                  <el-form-item label="文本内容：" v-if="checkText(item.content.text)==='text'">
                    <el-input v-model="item.content.text" class="style-tool-input" style="width: 200px;" />
                  </el-form-item>
                  <el-form-item label="标题层级：" v-else>
                    <el-select v-model="item.content.text" class="style-tool-select" :popper-append-to-body="false">
                      <el-option v-for="item in 9" :key="item" :label="`${item}级标题`" :value="`@CPLV${item}`" />
                    </el-select>
                  </el-form-item>
                  <br>
                  <el-form-item label="中文字体：">
                    <el-select v-model="item.style.font_family_cn" :placeholder="'宋体（默认）'" :popper-append-to-body="false" clearable class="style-tool-select" filterable>
                      <el-option
                        v-for="(value, key) in fontFamilyCn"
                        :key="value"
                        :label="key"
                        :value="value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="英文字体：">
                    <el-select v-model="item.style.font_family_en" :placeholder="'宋体（默认）'" :popper-append-to-body="false" clearable class="style-tool-select" filterable>
                      <el-option
                        v-for="(value, key) in fontFamilyEn"
                        :key="value"
                        :label="key"
                        :value="value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="数字字体：">
                    <el-select v-model="item.style.font_family_num" :placeholder="'宋体（默认）'" :popper-append-to-body="false" clearable class="style-tool-select" filterable>
                      <el-option
                        v-for="(value, key) in fontFamilyEn"
                        :key="value"
                        :label="key"
                        :value="value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="字号：">
                    <el-select v-model="item.style.font_size" placeholder="10.5" :popper-append-to-body="false" clearable class="style-tool-select">
                      <el-option v-for="item in fontSize" :key="item.key" :label="item.key" :value="item.value" />
                    </el-select>
                  </el-form-item>
                  <el-form-item label="文字颜色：">
                    <div class="font-style">
                      <div class="font-style-item">
                        <el-popover
                          placement="bottom"
                          width="220"
                          popper-class="style-tool-color-popover"
                          trigger="click">
                          <div class="style-tool-cmyk-box">
                            <span class="label">CMYK: </span>
                            <span v-if="item.style.font_color.cmyk"><el-input :value="item.style.font_color.cmyk[0]" @input="inputCmyk($event, 0, item)" placeholder="C"></el-input></span>
                            <span v-if="item.style.font_color.cmyk"><el-input :value="item.style.font_color.cmyk[1]" @input="inputCmyk($event, 1, item)" placeholder="M"></el-input></span>
                            <span v-if="item.style.font_color.cmyk"><el-input :value="item.style.font_color.cmyk[2]" @input="inputCmyk($event, 2, item)" placeholder="Y"></el-input></span>
                            <span v-if="item.style.font_color.cmyk"><el-input :value="item.style.font_color.cmyk[3]" @input="inputCmyk($event, 3, item)" placeholder="K"></el-input></span>
                            <el-color-picker :value="getColor(item.style.font_color.rgb)" size="mini" color-format="rgb"  @active-change="transformColor($event, item)" ref="picker" />
                          </div>
                          <div class="font-style-item-shadow" slot="reference">
                            <div class="font-style-item-shadow-font">A</div>
                            <div class="font-style-item-shadow-color" :style="{ 'background-color': getColor(item.style.font_color.rgb) }" />
                          </div>
                        </el-popover>
                      </div>
                    </div>
                  </el-form-item>
                  <el-form-item label="加粗：">
                    <el-select v-model="item.style.text_bold" placeholder="正常" :popper-append-to-body="false" class="style-tool-select" clearable>
                      <el-option label="粗1" :value="1" />
                      <el-option label="粗2" :value="2" />
                      <el-option label="粗3" :value="3" />
                      <el-option label="粗4" :value="4" />
                    </el-select>
                  </el-form-item>
                  <br>
                  <el-form-item label="文字位置：">
                    <el-radio-group v-model="item.content.align" class="m-r-20">
                      <el-radio label="left">居左</el-radio>
                      <el-radio label="center">居中</el-radio>
                      <el-radio label="right">居右</el-radio>
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item label="文字坐标：">
                    <span style="font-size: 12px; color: #606266;">水平位置</span>
                    <unit-input v-model="item.margin.before" step="1" unit="mm" min="0" />
                    <span>&nbsp;mm</span>
                  </el-form-item>
                </el-form>
              </div>
            </div>
          </el-row>
        </el-timeline-item>
        <el-timeline-item hide-timestamp :icon="actDot.includes('2') ? 'el-icon-arrow-down' : 'el-icon-arrow-right'" :type="actDot.includes('2') ? 'primary' : 'info'" size="large">
          <span class="timeline-title" @click="handleDot('2')">页脚配图</span>
          <el-button class="style-tool-button--text m-l-20" icon="el-icon-plus" @click="addImg">新增</el-button>
          <el-row v-if="actDot.includes('2')" class="config-item">
            <div class="img-item" v-for="(item, index) in imgComponents" :key="item.node_id">
              <div class="header">
                <div>页脚配图{{String.fromCharCode(index + 9312)}}</div>
                <el-button class="style-tool-button--text" icon="el-icon-delete" @click="delItem(item)">删除</el-button>
              </div>
              <div class="img-body">
                <el-form :inline="true" size="mini" label-position="right" label-width="100px">
                 <el-form-item label="配图上传：">
                    <el-row class="m-b-10">
                      <span style="display: inline-block; width: 60px">配图
                        <el-tooltip class="item" effect="light" content="提示：配图不支持tif, eps格式，需要该格式的图片可以上传到备用图" placement="top" :open-delay="300">
                          <i class="el-icon-info"></i>
                        </el-tooltip>
                      </span>
                      <el-badge :is-dot="!!item.content.url">
                        <el-button class="style-tool-button" @click="uploadImage">上传</el-button>
                      </el-badge>
                    </el-row>
                    <el-row>
                      <span style="display: inline-block; width: 60px">备用图 </span>
                      <el-badge :is-dot="!!item.content.alias_url">
                        <el-button class="style-tool-button" @click="uploadAliasImage">上传</el-button>
                      </el-badge>
                    </el-row>
                 </el-form-item>
                  <el-form-item label="图片坐标：">
                    <span class="label">X </span>
                    <unit-input v-model="item.margin.before" step="1" unit="mm" min="0" />
                    <span class="unit"> mm</span>
                  </el-form-item>
                  <el-form-item label="图片尺寸：">
                     <el-row class="connection-box">
                      <div class="connection-style">
                        <svg-icon name="chain" class="icon-connection"></svg-icon>
                      </div>
                      <div class="input-box">
                        <span class="label m-b-10">高 </span>
                        <unit-input v-model="item.img_size.height" step="1" min="0" unit="mm" @input="handleHeight($event, item)" />
                        <span class="unit"> mm</span>
                      </div>
                      <div class="input-box">
                        <span class="label">宽 </span>
                        <unit-input v-model="item.img_size.width" step="1" min="0" unit="mm" @input="handleWidth($event, item)" />
                        <span class="unit"> mm</span>
                      </div>
                    </el-row>
                  </el-form-item>
                </el-form>
              </div>
            </div>
          </el-row>
        </el-timeline-item>
        <el-timeline-item hide-timestamp :icon="actDot.includes('3') ? 'el-icon-arrow-down' : 'el-icon-arrow-right'" :type="actDot.includes('3') ? 'primary' : 'info'" size="large">
          <span class="timeline-title" @click="handleDot('3')">页脚页码</span>
          <el-button class="style-tool-button--text m-l-20" icon="el-icon-plus" @click="addPagenum">新增</el-button>
          <el-row v-if="actDot.includes('3')" class="config-item">
            <div class="pagenum-item" v-for="(item, index) in pagenumComponents" :key="item.node_id">
              <div class="header">
                <div>页脚页码{{String.fromCharCode(index + 9312)}}</div>
                <el-button class="style-tool-button--text" icon="el-icon-delete" @click="delItem(item)">删除</el-button>
              </div>
              <div class="pagenum-body">
                <el-form :inline="true" size="mini" label-position="right" label-width="100px">
                  <el-form-item label="页码样式：">
                    <el-select :value="checkPagenum(item.content.text)" @input="changePagenum($event, item.content)" class="style-tool-select" :popper-append-to-body="false">
                      <el-option label="1, 2, 3..." value="@PN" />
                      <el-option label="-1-, -2-, -3-..." value="-@PN-" />
                      <el-option label="·1·, ·2·, ·3·... " value="·@PN·" />
                      <el-option label="自定义" value="自定义" />
                    </el-select>
                  </el-form-item>
                  <el-form-item label="页码效果：">
                    <el-input v-model="item.content.text" class="style-tool-input" style="width: 100px;" placeholder="-@PN-" />
                  </el-form-item>
                  <br>
                  <el-form-item label="页码字体：">
                    <el-select v-model="item.style.font_family_num" :placeholder="'白正（默认）'" :popper-append-to-body="false" clearable class="style-tool-select" filterable>
                      <el-option
                        v-for="(value, key) in fontFamilyEn"
                        :key="value"
                        :label="key"
                        :value="value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="字号：">
                    <el-select v-model="item.style.font_size" placeholder="10.5" :popper-append-to-body="false" clearable class="style-tool-select">
                      <el-option v-for="item in fontSize" :key="item.key" :label="item.key" :value="item.value" />
                    </el-select>
                  </el-form-item>
                  <el-form-item label="页码颜色：">
                    <div class="font-style">
                      <div class="font-style-item">
                        <el-popover
                          placement="bottom"
                          width="220"
                          popper-class="style-tool-color-popover"
                          trigger="click">
                          <div class="style-tool-cmyk-box">
                            <span class="label">CMYK: </span>
                            <span v-if="item.style.font_color.cmyk"><el-input :value="item.style.font_color.cmyk[0]" @input="inputCmyk($event, 0, item)" placeholder="C"></el-input></span>
                            <span v-if="item.style.font_color.cmyk"><el-input :value="item.style.font_color.cmyk[1]" @input="inputCmyk($event, 1, item)" placeholder="M"></el-input></span>
                            <span v-if="item.style.font_color.cmyk"><el-input :value="item.style.font_color.cmyk[2]" @input="inputCmyk($event, 2, item)" placeholder="Y"></el-input></span>
                            <span v-if="item.style.font_color.cmyk"><el-input :value="item.style.font_color.cmyk[3]" @input="inputCmyk($event, 3, item)" placeholder="K"></el-input></span>
                            <el-color-picker :value="getColor(item.style.font_color.rgb)" size="mini" color-format="rgb"  @active-change="transformColor($event, item)" ref="picker" />
                          </div>
                          <div class="font-style-item-shadow" slot="reference">
                            <div class="font-style-item-shadow-font">A</div>
                            <div class="font-style-item-shadow-color" :style="{ 'background-color': getColor(item.style.font_color.rgb) }" />
                          </div>
                        </el-popover>
                      </div>
                    </div>
                  </el-form-item>
                  <br>
                  <el-form-item label="页码坐标：">
                    <span class="label">X </span>
                    <unit-input v-model="item.position.x" step="1" unit="mm"/>
                    <span class="unit"> mm</span>
                    <span style="padding-left: 40px;"></span>
                    <span class="label">Y </span>
                    <unit-input v-model="item.position.y" step="1" unit="mm"/>
                    <span class="unit"> mm</span>
                  </el-form-item>
                  <br>
                  <el-form-item>
                    <span style="display: inline-block; width: 60px" slot="label">配图
                        <el-tooltip class="item" effect="light" content="提示：配图不支持tif, eps格式，需要该格式的图片可以上传到备用图" placement="top" :open-delay="300">
                          <i class="el-icon-info"></i>
                        </el-tooltip>
                        ：
                      </span>
                      <el-badge :is-dot="!!item.content.url" style="display: inline">
                        <el-button class="style-tool-button" style="padding: 0;" @click="uploadPageNumImage">上传</el-button>
                      </el-badge>
                      <el-button class="style-tool-button m-l-5" style="padding: 0; vertical-align: middle; background: #ccc;" @click="delPageNumImg(item)">删除</el-button>
                  </el-form-item>
                  <template v-if="item.content.url">
                     <el-form-item>
                      <span style="display: inline-block; width: 60px" slot="label">备用图：
                        </span>
                        <el-badge :is-dot="!!item.content.alias_url" style="display: inline">
                          <el-button class="style-tool-button" style="padding: 0;" @click="uploadPageNumAliasImage">上传</el-button>
                        </el-badge>
                        <el-button class="style-tool-button m-l-5" style="padding: 0; vertical-align: middle; background: #ccc;" @click="item.content.alias_url = ''">删除</el-button>
                    </el-form-item>
                    <el-form-item label="图文偏移：">
                      <unit-input v-model="item.content.offset" step="1" unit="mm"/>
                      <span class="unit"> mm</span>
                    </el-form-item>
                    <el-form-item label="图片尺寸：">
                     <el-row class="connection-box">
                      <div class="connection-style">
                        <svg-icon name="chain" class="icon-connection"></svg-icon>
                      </div>
                      <div class="input-box">
                        <span class="label m-b-10">高 </span>
                        <unit-input v-model="item.img_size.height" step="1" min="0" unit="mm" @input="handleHeight($event, item)" />
                        <span class="unit"> mm</span>
                      </div>
                      <div class="input-box">
                        <span class="label">宽 </span>
                        <unit-input v-model="item.img_size.width" step="1" min="0" unit="mm" @input="handleWidth($event, item)" />
                        <span class="unit"> mm</span>
                      </div>
                    </el-row>
                  </el-form-item>
                  </template>
                </el-form>
              </div>
            </div>
          </el-row>
        </el-timeline-item>
      </el-timeline>
    </div>
    <FbdEditor
      v-if="component.fbd.enable_fbd"
      v-model="component.fbd.code_template"
      :options="{ height: 150 }"
    />
  </el-card>
</template>

<script>
import UnitInput from '../components/UnitInput';
import { getDPI } from '@/scripts/helper';
import { defaultFormat, getDefaultText, getDefaultImg, getDefaultPagenum } from './assets/format.js';
import { fontSize, fontFamilyCn, fontFamilyEn, replaceFontSizeMap, webFontFamily } from '@/assets/styleTool/map';
import _ from 'lodash';
import { catalog } from '../assets/componentCatalog.js';

import editor from 'hexin-ckeditor-doc-vue/dist/editor.umd.js';
import 'hexin-ckeditor-doc-vue/dist/editor.css';
const FbdEditor = editor.FbdEditor;

export default {
  components: {
    UnitInput,
    FbdEditor,
  },
  props: {
    styleData: {
      type: Object,
      required: true
    }
  },
  created () {
    this.init();
  },
  data () {
    return {
      comparePic: {
        url: '',
        mode: 'background'
      },
      compareStyle: {
        opacity: 0.5
      },
      dpi: getDPI(),
      fileVal: '',
      files: null,
      fontSize,
      data: null,
      actImg: 0,
      actPageNumImg: 0,
      checkList: [],
      actDot: ['0'],
      formatData: [],
      defaultFormat: defaultFormat(this.styleData),
      tabIndex: 0,
      closed: true,
      actFormat: {
        data: {}
      },
      condition: {
        formatType: '',
        formatModel: '',
        search: ''
      },
      margin_type: 'footer',
    };
  },
  destroyed () {
    this.removeComparePic();
  },
  watch: {
    comparePic: {
      deep: true,
      handler (val) {
        const { url, mode } = val;
        const page = document.querySelector('.page');
        if (url && mode === 'background') {
          page.style.background = `linear-gradient(rgba(255, 255, 255, ${1 - this.compareStyle.opacity}), rgba(255, 255, 255, ${1 - this.compareStyle.opacity})), url('${url}') no-repeat 0 100%`;
          page.style.backgroundSize = '100% auto';
        } else {
          page.style.background = '#fff';
        }
        if (mode === 'free' && url) {
          const page = document.querySelector('.page');
          this.compareStyle.width = page.offsetWidth + 'px';
        }
      }
    },
    compareStyle: {
      deep: true,
      handler (val) {
        if (this.comparePic.mode === 'background') {
          const page = document.querySelector('.page');
          page.style.background = `linear-gradient(rgba(255, 255, 255, ${1 - val.opacity}), rgba(255, 255, 255, ${1 - val.opacity})), url('${this.comparePic.url}') no-repeat 0 100%`;
          page.style.backgroundSize = '100% auto';
        }
      }
    }
  },
  computed: {
    lineSpacing () {
      let { styleData: { components: [{ default_style: { font_size, line_spacing } }] } } = this;
      font_size = replaceFontSizeMap[font_size] || font_size;
      line_spacing = typeof line_spacing === 'number' ? line_spacing * parseFloat(font_size) + 'pt' : line_spacing;
      return line_spacing;
    },
    fontFamilyCn () {
      const { extra_font_list } = this.styleData;
      const temp = {};
      extra_font_list.filter(item => item.type === 'cn').forEach(item => temp[item.font_name] = item.font_name);
      return { ...fontFamilyCn, ...temp };
    },
    fontFamilyEn () {
      const { extra_font_list } = this.styleData;
      const temp = {};
      extra_font_list.filter(item => item.type === 'en').forEach(item => temp[item.font_name] = item.font_name);
      return { ...fontFamilyEn, ...temp };
    },
    formatTypeList () {
      return catalog.module.footer.type;
    },
    imgStyle () {
      const { imgComponents: [img] } = this;
      if (!img) return {};
      const { img_size: { height, width } } = img;
      return {
        width: parseFloat(width) ? width : '100%',
        height: parseFloat(height) ? height : '100%',
        marginLeft: img.margin.before
      };
    },
    textContainerStyle () {
      const { component: { offset }, textComponents, styleData: { components: [{ default_style: { font_size, line_spacing } }] } } = this;
      const maxFontSize = Math.max(...textComponents.map(item => {
        let itemFontSize = item.style?.font_size;
        itemFontSize = itemFontSize ? (replaceFontSizeMap[itemFontSize] || itemFontSize) : (replaceFontSizeMap[font_size] || font_size);
        return fontSize.find(item => item.value === itemFontSize).key * 0.376;
      }));
      let defaultFontSize = (replaceFontSizeMap[font_size] || font_size);
      defaultFontSize = fontSize.find(item => item.value === defaultFontSize).key * 0.376;
      return {
        lineHeight: maxFontSize + 'mm',
        height: maxFontSize + 'mm',
        marginTop: parseFloat(typeof line_spacing === 'number' ? defaultFontSize * line_spacing : line_spacing) - parseFloat(offset) + 'mm'
      };
    },
    pageMarginRightStyle () {
      const { components: [{ page_cfg: { page_margin } }] } = this.styleData;
      return {
        width: page_margin.right
      };
    },
    pagenumStyle () {
      const { pagenumComponents: [pagenum], styleData: { components: [{ page_cfg: { page_core_size }, default_style: { font_size, font_family_cn, font_color: { rgb } } }] } } = this;
      if (!pagenum) return;
      const { position: { x, y }, style: { font_size: itemFontSize, font_family_cn: itemFontFamilyCn, font_color: { rgb: itemRgb } } } = pagenum;
      const calcFontSize = replaceFontSizeMap[itemFontSize] || itemFontSize || replaceFontSizeMap[font_size] || font_size;
      return {
        fontSize: calcFontSize,
        lineHeight: calcFontSize,
        fontFamily: webFontFamily[itemFontFamilyCn] || webFontFamily[font_family_cn],
        color: `rgb(${itemRgb?.join(',') || rgb?.join(',')})`,
        left: x,
        textAlign: 'center',
        top: parseFloat(y) - parseFloat(page_core_size.height) + 'mm'
      };
    },
    marginContainerStyle () {
      const { margin_before, relative_margin } = this.component;
      return {
        marginLeft: margin_before,
        marginTop: relative_margin
      };
    },
    pageStyle () {
      const { components: [{ page_cfg: { page_size } }] } = this.styleData;
      return {
        width: page_size.width
      };
    },
    pageMarginStyle () {
      const { components: [{ page_cfg: { page_margin } }] } = this.styleData;
      return {
        height: page_margin.bottom
      };
    },
    pageMarginLeftStyle () {
      const { components: [{ page_cfg: { page_margin } }] } = this.styleData;
      return {
        width: page_margin.left
      };
    },
    leftGuideStyle () {
      const { components: [{ page_cfg: { page_margin } }] } = this.styleData;
      return {
        left: page_margin.left
      };
    },
    rightGuideStyle () {
      const { components: [{ page_cfg: { page_margin } }] } = this.styleData;
      return {
        right: page_margin.right
      };
    },
    bottomGuideStyle () {
      const { components: [{ page_cfg: { page_margin } }] } = this.styleData;
      return {
        bottom: page_margin.bottom
      };
    },
    imgComponents () {
      const { component: { data } } = this;
      return data.filter(item => item.node_type === 'image');
    },
    pagenumComponents () {
      const { component: { data } } = this;
      return data.filter(item => item.node_type === 'page_number');
    },
    textComponents () {
      const { component: { data } } = this;
      return data.filter(item => item.node_type === 'text');
    },
    formatList () {
      const { formatType } = this.condition;
      const formatList = this.formatTypeList.find(item => item.value === formatType)?.model;
      if (!formatList) return [];
      return formatList;
    },
    formatDataList () {
      const { search } = this.condition;
      return this.formatData.filter(item => item.data.name.indexOf(search) >= 0);
    },
    config () {
      return this.component;
    },
    component () {
      const { data: { margin_components }, margin_type } = this;
      const { page_selector } = this.tabs[this.tabIndex];
      const data = margin_components.find(item => item.margin_type === margin_type && item.page_selector === page_selector);
      data.fbd = data.fbd || {};
      data.fbd.enable_fbd = data.fbd.enable_fbd || true;
      data.fbd.code_template = data.fbd.code_template || '';
      console.log(data.fbd.code_template, 'data.fbd.code_template');
      return data;
    },
    tabs () {
      const tabs = [];
      const { checkList } = this;
      if (checkList.includes('页脚分左右页显示')) {
        tabs.push({ name: '左页页脚', index: tabs.length, page_selector: 'odd' }, { name: '右页页脚', index: tabs.length + 1, page_selector: 'even' });
      } else {
        tabs.push({ name: '左右页脚', index: tabs.length, page_selector: 'page' });
      }
      return tabs;
    }
  },
  methods: {
    delPageNumImg (item) {
      console.log(item);
      item.content.url = '';
      item.content.offset = '';
      item.alias_url = '';
      item.img_size = {
        width: '',
        height: ''
      };
    },
    addComparePic () {
      const files = this.$refs.compareFile.files;
      if (files.length === 0) return;
      this.comparePic.url = URL.createObjectURL(files[0]);
      this.$refs.compareFile.value = '';
    },
    removeComparePic () {
      if (!this.comparePic.url) return;
      URL.revokeObjectURL(this.comparePic.url);
      this.comparePic.url = '';
    },
    handleWidth (val, { img_size, content: { url } }) {
      if (val !== '' && url) {
        const img = new Image();
        img.src = url + '?x-oss-process=image/format,jpg';
        img.onload = () => {
          img_size.height = Math.round((parseFloat(val) / (img.width / this.dpi.y * 25.4) * (img.height / this.dpi.x * 25.4)) * 10) / 10 + 'mm';
          img_size.width = val;
        };
      }
    },
    handleHeight (val, { img_size, content: { url } }) {
      if (val !== '' && url) {
        const img = new Image();
        img.src = url + '?x-oss-process=image/format,jpg';
        img.onload = () => {
          img_size.width = Math.round((parseFloat(val) / (img.height / this.dpi.y * 25.4) * (img.width / this.dpi.x * 25.4)) * 10) / 10 + 'mm';
          img_size.height = val;
        };
      }
    },
    uploadImage () {
      this.actImg = 0;
      this.$nextTick(function () {
        this.$refs.file.click();
      });
    },
    uploadAliasImage () {
      this.actImg = 1;
      this.$nextTick(function () {
        this.$refs.file.click();
      });
    },
    uploadPageNumImage () {
      this.actPageNumImg = 0;
      this.$nextTick(function () {
        this.$refs.pageNumFile.click();
      });
    },
    uploadPageNumAliasImage () {
      this.actPageNumImg = 1;
      this.$nextTick(function () {
        this.$refs.pageNumFile.click();
      });
    },
    setComponent () {
      const data = _.cloneDeep(this.config);
      delete data.page_selector;
      delete data.margin_type;
    },
    calcStyle (item) {
      const { content: { align }, style: { font_size: itemFontSize, font_family_cn: itemFontFamilyCn, font_color: { rgb: itemRgb } }, margin: { before } } = item;
      const { styleData: { components: [{ default_style: { font_size, font_family_cn, font_color: { rgb } } }] } } = this;
      const style = {
        fontSize: replaceFontSizeMap[itemFontSize] || itemFontSize || replaceFontSizeMap[font_size] || font_size,
        fontFamily: webFontFamily[itemFontFamilyCn] || webFontFamily[font_family_cn],
        color: `rgb(${itemRgb?.join(',') || rgb?.join(',')})`,
      };
      if (align === 'center') {
        Object.assign(style, { marginLeft: before, left: '50%', transform: 'translate(-50%, 0)' });
      } else if (align === 'right') {
        Object.assign(style, { marginRight: `${-parseFloat(before)}mm`, right: '0mm' });
      } else {
        Object.assign(style, { marginLeft: before, left: '0mm' });
      }
      return style;
    },
    setPageNumUrl (url, item) {
      if (this.actPageNumImg === 1) {
        item.content.alias_url = url;
        return;
      }
      const img = new Image();
      img.src = url;
      item.content.url = url;
      img.onload = () => {
        const type = this.actFormat.type && this.actFormat.type.split('_') || [];
        let { components: [{ page_cfg: { page_margin: { top: maxHeight }, page_core_size: { width: maxWidth } } }] } = this.styleData;
        maxHeight = parseFloat(maxHeight);
        maxWidth = parseFloat(maxWidth);
        const width = Math.round((img.width / this.dpi.x * 25.4) * 10) / 10;
        const height = Math.round((img.height / this.dpi.y * 25.4) * 10) / 10;
        const scale = width / height;
        const heightValue = parseFloat(item.img_size.width) / scale;
        if (type.includes('background')) {
          if (heightValue > maxHeight) { // 这时候宽是百分百。高大了，取高为最大值，缩小宽度
            item.img_size.height = maxHeight + 'mm';
            item.img_size.width = maxHeight * scale + 'mm';
          } else {
            item.img_size.height = heightValue + 'mm';
          }
          return;
        } else {
          if (maxWidth / maxHeight > scale) {
            if (height > maxHeight) {
              item.img_size = {
                width: maxHeight * scale + 'mm',
                height: maxHeight + 'mm'
              };
              return;
            }
          } else {
            if (width > maxWidth) {
              item.img_size = {
                width: maxWidth + 'mm',
                height: maxWidth / scale + 'mm'
              };
              return;
            }
          }
        }
        item.img_size = {
          width: width + 'mm',
          height: height + 'mm'
        };
      };
    },
    setUrl (url, item) {
      if (this.actImg === 1) {
        item.content.alias_url = url;
        return;
      }
      const img = new Image();
      img.src = url + '?x-oss-process=image/format,jpg';
      item.content.url = url;
      img.onload = () => {
        const type = this.actFormat.type && this.actFormat.type.split('-') || [];
        let { components: [{ page_cfg: { page_margin: { bottom: maxHeight }, page_core_size: { width: maxWidth } } }] } = this.styleData;
        maxHeight = parseFloat(maxHeight);
        maxWidth = parseFloat(maxWidth);
        item.natural_size = { width: img.width, height: img.height };
        const width = Math.round((img.width / this.dpi.x * 25.4) * 10) / 10;
        const height = Math.round((img.height / this.dpi.y * 25.4) * 10) / 10;
        const scale = width / height;
        const heightValue = parseFloat(item.img_size.width) / scale;
        if (type.includes('background')) {
          if (heightValue > maxHeight) { // 这时候宽是百分百。高大了，取高为最大值，缩小宽度
            item.img_size.height = maxHeight + 'mm';
            item.img_size.width = maxHeight * scale + 'mm';
          } else {
            item.img_size.height = heightValue + 'mm';
          }
          return;
        } else {
          if (maxWidth / maxHeight > scale) {
            if (height > maxHeight) {
              item.img_size = {
                width: maxHeight * scale + 'mm',
                height: maxHeight + 'mm'
              };
              return;
            }
          } else {
            if (width > maxWidth) {
              item.img_size = {
                width: maxWidth + 'mm',
                height: maxWidth / scale + 'mm'
              };
              return;
            }
          }
        }
        item.img_size = {
          width: width + 'mm',
          height: height + 'mm'
        };
      };
    },
    fileChange (evt, item) {
      const file = evt.target.files[0];
      this.files = file;
      this.fileUploadOss(item);
    },
    pageNumFileChange (evt, item) {
      const file = evt.target.files[0];
      this.files = file;
      this.pageNumFileUploadOss(item);
    },
    transformColor (val, item) {
      let rgb = val && val.match(/(?<=\()(\d+), *(\d+), *(\d+)(?=\))/)[0] || '';
      if (rgb) {
        rgb = rgb.split(',').map(item => +item);
      } else {
        rgb = [];
      }
      item.style.font_color.rgb = rgb;
      if (rgb.length) {
        item.style.font_color.cmyk = this.rgb2cmyk(rgb);
      } else {
        item.style.font_color.cmyk = [];
      }
    },
    inputCmyk (val, position, item) {
      val = +val.replace(/[^0-9]/g, '');
      val = val < 0 ? 0
        : val > 100 ? 100 : val;
      const { font_color, font_color: { cmyk } } = item.style;
      cmyk.splice(position, 1, val);
      if (!cmyk || cmyk.length !== 4) return;
      font_color.rgb = this.cmykToRGB(cmyk);
    },
    rgb2cmyk ([r, g, b]) {
      let c = 1 - (r / 255);
      let m = 1 - (g / 255);
      let y = 1 - (b / 255);
      let k = Math.min(c, Math.min(m, y));
      c = Math.round((c - k) / (1 - k) * 100);
      m = Math.round((m - k) / (1 - k) * 100);
      y = Math.round((y - k) / (1 - k) * 100);
      k = Math.round(k * 100);
      c = isNaN(c) ? 0 : c;
      m = isNaN(m) ? 0 : m;
      y = isNaN(y) ? 0 : y;
      k = isNaN(k) ? 0 : k;
      return [c, m, y, k];
    },
    cmykToRGB ([c, m, y, k]) {
      const r = Math.round(255 * (1 - c / 100) * (1 - k / 100));
      const g = Math.round(255 * (1 - m / 100) * (1 - k / 100));
      const b = Math.round(255 * (1 - y / 100) * (1 - k / 100));
      return [r, g, b];
    },
    getColor (rgb) {
      if (!rgb || !rgb.length) {
        return 'rgb(0, 0, 0)';
      }
      return `rgb(${rgb.join()})`;
    },
    init () {
      this.data = this.styleData.components[0];
      let { margin_components } = this.data;
      if (!margin_components) margin_components = this.$set(this.data, 'margin_components', []);
      const { margin_type } = this;
      if (!margin_components.length || !margin_components.find(item => item.margin_type === margin_type)) {
        margin_components.push(this.defaultMarginComponent());
      }
      const marginComponent = margin_components.find(item => item.margin_type === margin_type);
      const { page_selector } = marginComponent;
      if (page_selector !== 'page') {
        this.checkList.push('页脚分左右页显示');
      }
    },
    addText () {
      const { component: { data } } = this;
      data.push(getDefaultText());
      if (!this.actDot.includes('1')) this.handleDot('1');
    },
    addImg () {
      const { component: { data }, imgComponents } = this;
      if (imgComponents.length > 0) {
        this.$message.info('目前暂时只支持一个配图');
        return;
      }
      data.push(getDefaultImg());
      if (!this.actDot.includes('2')) this.handleDot('2');
    },
    addPagenum () {
      const { component: { data }, pagenumComponents } = this;
      if (pagenumComponents.length > 0) {
        this.$message.info('目前暂时只支持一个页码');
        return;
      }
      data.push(getDefaultPagenum(this.styleData));
      if (!this.actDot.includes('3')) this.handleDot('3');
    },
    delItem ({ node_id }) {
      const { component: { data } } = this;
      const index = data.findIndex(item => item.node_id === node_id);
      data.splice(index, 1);
    },
    checkPagenum (pagenum) {
      if (pagenum === '@PN' || pagenum === '-@PN-' || pagenum === '·@PN·') return pagenum;
      return '自定义';
    },
    changePagenum (pagenum, content) {
      if (pagenum === '自定义') return;
      content.text = pagenum;
    },
    checkText (text) {
      return /^@CPLV[1-9]$/.test(text) ? 'title' : 'text';
    },
    changeTextType (val, content) {
      if (val === 'text') {
        content.text = '';
      } else {
        content.text = '@CPLV1';
      }
    },
    defaultMarginComponent (page_selector = 'page') {
      return {
        margin_type: this.margin_type,
        page_selector,
        relative_margin: '0mm',
        margin_before: '0mm',
        offset: '0mm',
        data: []
      };
    },
    handleDot (item) {
      const index = this.actDot.indexOf(item);
      if (index >= 0) {
        this.actDot.splice(index, 1);
      } else {
        this.actDot.push(item);
      }
    },
    selectFormat (item) {
      this.actFormat = item;
      const data = _.cloneDeep(item.data.data);
      if (data.page_selector) delete data.page_selector;
      Object.assign(this.component, data);
    },
    switchFormat (val) {
      this.condition.formatType = val;
    },
    changeCheckList (val) {
      let { margin_components } = this.data;
      margin_components = margin_components.filter(item => item.margin_type !== this.margin_type);
      if (val.includes('页脚分左右页显示')) {
        const pageMargin = this.data.margin_components.find(item => item.margin_type === this.margin_type && item.page_selector === 'page');
        if (pageMargin) {
          margin_components.push(Object.assign(_.cloneDeep(pageMargin), { page_selector: 'odd' })
            , Object.assign(_.cloneDeep(pageMargin), { page_selector: 'even' }));
        } else {
          margin_components.push(this.defaultMarginComponent('odd'), this.defaultMarginComponent('even'));
        }
      } else {
        margin_components.push(this.defaultMarginComponent());
      }
      this.data.margin_components = margin_components;
      this.tabIndex = this.tabs[0]?.index;
      this.checkList = val;
    },
    changeEnabledFbd (enabledFbd) {
      const { data: { margin_components }, margin_type } = this;
      const { page_selector } = this.tabs[this.tabIndex];
      const component = margin_components.find(item => item.margin_type === margin_type && item.page_selector === page_selector);
      component.fbd.enable_fbd = enabledFbd;
      this.data.margin_components = [...this.data.margin_components];
    },
  }
};
</script>

<style lang="scss" scoped>
$main-color: #409EFF;
$danger-color: #F56C6C;
.book-header {
  /deep/ .el-card__body {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .preview {
    margin-top: 20px;
    width: 100%;
    flex: none;
    background: #ccc;
    box-shadow: 0px 0px 4px black inset;
    overflow: auto;
    padding: 10px;
    position: relative;
    .btn {
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 2;
    }
    .page {
      background-color: #fff;
      position: relative;
      margin: 0 auto;

      .guide {
        position: absolute;
        z-index: 2;
        pointer-events: none;
      }
      .v-guide {
        height: 100%;
        border-right: 1px dashed #ccc;
        top: 0;
      }
      .h-guide {
        width: 100%;
        border-bottom: 1px dashed #ccc;
        left: 0;
      }
      .page-margin {
        display: flex;
        .margin-left {
          flex: none;
        }
        .margin-center {
          flex: auto;
          position: relative;
          .margin-inner {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
          }
          .margin-img-box {
            transition: all .2s;
            .img-background {
              width: 100%;
              height: 100%;
              background: url('./assets/empty.png') no-repeat 0 0;
              background-size: 100% 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              .add-img-btn {
                color: #999;
                cursor: pointer;
              }
            }
          }
          .margin-text-box {
            position: relative;
            transition: all .2s;
            .margin-text {
              position: absolute;
            }
          }
          .margin-page-num {
            position: absolute;
            display: flex;
            flex-direction: column;
            z-index: 2;
          }
        }
        .margin-right {
          flex: none;
        }
      }
      .page-core {
        height: 40px;
        color: #ccc;
        text-align: center;
        line-height: 40px;
        user-select: none;
        display: flex;
        .page-core-left, .page-core-right {
          flex: none;
        }
        .page-core-center {
          flex: auto;
          position: relative;
        }
      }
    }
  }
  .header {
    flex: none;
    display: flex;
    align-items: center;
    .inline-tab {
      /deep/ .tabs-item {
        min-width: 100px;
      }
      /deep/ .slide {
        transition: transform .2s;
      }
    }
    .check-box {
      margin-left: 10px;
      /deep/ .el-checkbox {
        margin: 10px;
      }
      /deep/ .el-checkbox__label {
        width: unset;
      }
    }
  }
  .style-lib {
    flex: none;
    position: relative;
    max-height: 400px;
    border: 1px solid #ccc;
    margin-top: 4px;
    .style-lib-tabs {
      padding: 0 20px;
      /deep/ .el-tabs__header {
        margin-bottom: unset;
      }
      /deep/ .el-tabs__item {
        height: 50px;
      }
      .tab-item {
        display: flex;
        flex-direction: column;
        .tab-item-img {
          flex: none;
          height: 30px;
        }
        .tab-item-text {
          flex: none;
          height: 20px;
          line-height: 20px;
          font-size: 12px;
          text-align: center;
        }
      }
    }
    .toggle {
      position: absolute;
      right: -1px;
      top: 0;
      transform: translate(0, -100%);
      border: 1px solid #ccc;
      border-bottom-color: transparent;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      padding: 10px 4px;
      background: #fff;
      cursor: pointer;
      .el-icon-caret-top {
        transition: transform .2s;
      }
    }
    .condition-box {
      display: flex;
      align-items: center;
      color: #333;
      height: 40px;
      overflow: hidden;
      transition: all 0.2s;
      .left {
        flex: auto;
        display: flex;
        align-items: center;
        overflow-x: auto;
        .format-item {
          font-size: 13px;
          padding: 2px 4px;
          padding-top: 0.1px;
          margin-left: 20px;
          color: #666;
          border-radius: 3px;
          cursor: pointer;
          &:hover {
            color: #fff;
            background: $main-color;
          }
        }
        .act-item {
          color: #fff;
          background: $main-color;
        }
      }
      .right {
        flex: none;
        display: flex;
        align-items: center;
        font-size: 13px;
        padding: 0 10px;
        i {
          color: #C0C4CC;
        }
        input {
          outline: none;
          border: none;
          border-bottom: 1px solid #ccc;
          padding-left: 10px;
          width: 120px;
          font-size: 13px;
          color: #919699;
          &::-webkit-input-placeholder {
            color: #DCDFE6;
          }
        }
      }
    }
    .style-box {
      transition: all 0.2s;
      display: flex;
      flex-wrap: wrap;
      padding: 10px;
      max-height: 200px;
      overflow-y: auto;
      .item {
        width: 220px;
        height: 90px;
        margin: 4px;
        border: 1px solid transparent;
        border-radius: 3px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 20px;
        cursor: pointer;
        position: relative;
        .del {
          position: absolute;
          right: 0;
          top: 0;
          transform: translate(50%, -50%);
          z-index: 2;
          display: none;
        }
        .img {
          height: 40px;
          flex: none;
        }
        .button-box {
          flex: auto;
          align-items: center;
          justify-content: center;
          display: none;
        }
        .text-box {
          flex: auto;
          align-items: center;
          justify-content: center;
          display: flex;
          .el-icon-success {
            color: $main-color;
            margin-right: 6px;
          }
        }
        &:hover {
          border-color: $main-color;
        }
        &:hover {
          .del {
            display: unset;
          }
          .button-box {
            display: flex;
          }
          .text-box {
            display: none;
          }
        }
      }
      .act-item {
        background-color: #409eff1f;
      }
    }
  }
  .closed {
    border-color: transparent;
    .toggle {
      border: 1px solid #ccc;
      border-radius: 4px;
      .el-icon-caret-top {
        transform: rotate(180deg);
      }
    }
    .condition-box {
      height: 0;
    }
    .style-box {
      overflow: hidden;
      height: 0;
      padding: 0;
    }
    .style-lib-tabs {
      height: 0;
      overflow: hidden;
    }
  }
  .config {
    flex: auto;
    overflow-y: auto;
    padding: 20px;
    .label {
      font-size: 12px;
      color: #606266;
    }
    .connection-box {
      width: 110px;
      position: relative;
      .connection-style {
        position: absolute;
        right: -20px;
        width: 20px;
        top: 14px;
        bottom: 14px;
        border: 1px solid #ccc;
        border-left-color: transparent;
        .icon-connection {
          position: absolute;
          right: -30px;
          top: 60%;
          transform: translate(0, -50%);
        }
      }
    }
    .timeline-title {
      font-weight: 500;
      cursor: pointer;
    }
    /deep/ .el-radio {
      margin-right: 10px;
      font-size: 12px;
    }
    /deep/ .el-radio__label {
      width: unset;
      font-size: 12px;
    }
    /deep/ .el-form-item__label {
      font-size: 12px;
    }
    /deep/ .el-form-item {
      margin-bottom: 0;
    }
    .config-item {
      padding-top: 20px;
      padding-left: 20px;
      .text-item, .img-item, .pagenum-item {
        .header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 4px;
          font-size: 13px;
          .style-tool-button--text {
            color: #A2A2A2;
            font-size: 12px;
            &:hover {
              color: $danger-color;
            }
          }
        }
        .text-body, .img-body, .pagenum-body {
          background-color: #F2F6FC;
          padding: 20px;
        }
      }
      .text-item + .text-item {
        margin-top: 10px;
      }
    }
  }
}
</style>
