import { setId } from '@/scripts/helper';
import { fontSize, replaceFontSizeMap } from '@/assets/styleTool/map';

export const calcFontHeight = ({ font_size, line_spacing }) => {
  const replaceFontSize = replaceFontSizeMap[font_size];
  if (replaceFontSize) font_size = replaceFontSize;
  font_size = fontSize.find(item => item.value === font_size).key * 0.376;
  return typeof line_spacing === 'number' ? font_size * (line_spacing + 1) + 'mm' : parseFloat(line_spacing) + font_size + 'mm';
};

const getStyle = () => {
  return {
    font_size: '',
    font_family_cn: '',
    font_family_en: '',
    font_family_num: '',
    text_bold: '',
    font_color: {
      rgb: [],
      cmyk: []
    }
  };
};

export const getDefaultText = () => {
  return {
    node_id: setId(),
    node_type: 'text',
    content: {
      text: '',
      align: 'left'
    },
    margin: {
      before: '0mm'
    },
    style: getStyle()
  };
};

export const getDefaultImg = () => {
  return {
    node_id: setId(),
    node_type: 'image',
    content: {
      url: '',
      alias_url: ''
    },
    margin: {
      before: '0mm'
    },
    img_size: {
      width: '0mm',
      height: '0mm'
    }
  };
};

export const getDefaultPagenum = (cfg) => {
  const { book_page_cfg: { page_core_size } } = cfg;
  return {
    node_id: setId(),
    node_type: 'page_number',
    content: {
      text: '@PN',
      url: '',
      alias_url: '',
      offset: ''
    },
    position: {
      x: '0mm',
      y: page_core_size.height
    },
    style: getStyle(),
    img_size: {
      width: '',
      height: ''
    }
  };
};

export const defaultFormat = function (cfg) {
  if (!cfg) return [];
  const { components: [{ default_style, page_cfg: { page_core_size, page_size, page_margin } }] } = cfg;
  return [
    {
      name: '空白（纯页码居左）',
      type: 'page_nubmer',
      img: require('./img/空白（纯页码居左）.jpg'),
      data: {
        relative_margin: '0mm',
        offset: '0mm',
        margin_before: '0mm',
        data: [
          {
            node_id: setId(),
            node_type: 'page_number',
            content: {
              text: ''
            },
            position: {
              'x': '0mm',
              'y': '0mm'
            },
            style: getStyle()
          }
        ]
      }
    },
    {
      name: '空白（纯页码居中）',
      type: 'page_nubmer',
      img: require('./img/空白（纯页码居中）.jpg'),
      data: {
        relative_margin: '0mm',
        offset: '0mm',
        margin_before: '0mm',
        data: [
          {
            node_id: setId(),
            node_type: 'page_number',
            content: {
              text: ''
            },
            position: {
              'x': (page_core_size.width) / 2,
              'y': -(page_core_size.height)
            },
            style: getStyle()
          }
        ]
      }
    },
    {
      name: '空白（纯页码居右）',
      type: 'page_nubmer',
      img: require('./img/空白（纯页码居右）.jpg'),
      data: {
        relative_margin: '0mm',
        offset: '0mm',
        margin_before: '0mm',
        data: [
          {
            node_id: setId(),
            node_type: 'page_number',
            content: {
              text: ''
            },
            position: {
              'x': (page_core_size.width) - 2,
              'y': -(page_core_size.height)
            },
            style: getStyle()
          }
        ]
      }
    },
    {
      name: '空白（居右页码+静态文字）',
      type: 'page_number-text',
      img: require('./img/空白（居右页码+静态文字）.jpg'),
      data: {
        relative_margin: '0mm',
        offset: '0mm',
        margin_before: '0mm',
        data: [
          {
            node_id: setId(),
            node_type: 'text',
            content: {
              text: '',
              align: 'left'
            },
            margin: {
              before: '0mm'
            },
            style: getStyle()
          },
          {
            node_id: setId(),
            node_type: 'page_number',
            content: {
              text: ''
            },
            position: {
              'x': (page_core_size.width) - 2,
              'y': -(page_core_size.height)
            },
            style: getStyle()
          }
        ]
      }
    },
    {
      name: '空白（居中页码+插图）',
      type: 'image-page_number',
      img: require('./img/空白（居中页码+插图）.jpg'),
      data: {
        relative_margin: '0mm',
        offset: calcFontHeight(default_style),
        margin_before: '0mm',
        data: [

          {
            node_id: setId(),
            node_type: 'image',
            content: {
              url: ''
            },
            margin: {
              before: (page_core_size.width) - 4,
            },
            img_size: {
              width: calcFontHeight(default_style),
              height: calcFontHeight(default_style)
            },
          },
          {
            node_id: setId(),
            node_type: 'page_number',
            content: {
              text: ''
            },
            position: {
              'x': (page_core_size.width) / 2 - 4,
              'y': -(page_core_size.height)
            },
            style: getStyle()
          }
        ]
      }
    },
    {
      name: '空白（居右页码+插图）',
      type: 'image-page_number',
      img: require('./img/空白（居右页码+插图）.jpg'),
      data: {
        relative_margin: '0mm',
        offset: calcFontHeight(default_style),
        margin_before: '0mm',
        data: [

          {
            node_id: setId(),
            node_type: 'image',
            content: {
              url: ''
            },
            margin: {
              before: (page_core_size.width) - 4,
            },
            img_size: {
              width: calcFontHeight(default_style),
              height: calcFontHeight(default_style)
            },
          },
          {
            node_id: setId(),
            node_type: 'page_number',
            content: {
              text: ''
            },
            position: {
              'x': (page_core_size.width) - 2,
              'y': -(page_core_size.height)
            },
            style: getStyle()
          }
        ]
      }
    },
    {
      name: '空白（版心宽+居中页码）',
      type: 'background-page_nubmer',
      img: require('./img/空白（版心宽+居中页码）.jpg'),
      data: {
        relative_margin: '0mm',
        offset: calcFontHeight(default_style),
        margin_before: '0mm',
        data: [

          {
            node_id: setId(),
            node_type: 'image',
            content: {
              url: ''
            },
            margin: {
              before: '0mm',
            },
            img_size: {
              width: page_core_size.width,
              height: page_margin.bottom
            },
          },
          {
            node_id: setId(),
            node_type: 'page_number',
            content: {
              text: ''
            },
            position: {
              'x': (page_core_size.width) / 2 - 2,
              'y': -(page_core_size.height)
            },
            style: getStyle()
          }
        ]
      }
    },
    {
      name: '空白（版心宽+居右页码）',
      type: 'background-page_nubmer',
      img: require('./img/空白（版心宽+居右页码）.jpg'),
      data: {
        relative_margin: '0mm',
        offset: calcFontHeight(default_style),
        margin_before: '0mm',
        data: [

          {
            node_id: setId(),
            node_type: 'image',
            content: {
              url: ''
            },
            margin: {
              before: '0mm',
            },
            img_size: {
              width: page_core_size.width,
              height: page_margin.bottom
            },
          },
          {
            node_id: setId(),
            node_type: 'page_number',
            content: {
              text: ''
            },
            position: {
              'x': (page_core_size.width) - 2,
              'y': -(page_core_size.height)
            },
            style: getStyle()
          }
        ]
      }
    },
    {
      name: '页宽+居右页码',
      type: 'background-page_nubmer',
      img: require('./img/页宽+居右页码.jpg'),
      data: {
        relative_margin: '0mm',
        offset: calcFontHeight(default_style),
        margin_before: '0mm',
        data: [

          {
            node_id: setId(),
            node_type: 'image',
            content: {
              url: ''
            },
            margin: {
              before: '0mm',
            },
            img_size: {
              width: page_core_size.width,
              height: page_margin.bottom
            },
          },
          {
            node_id: setId(),
            node_type: 'page_number',
            content: {
              text: ''
            },
            position: {
              'x': (page_core_size.width) - 2,
              'y': -(page_core_size.height)
            },
            style: getStyle()
          }
        ]
      }
    },
    {
      name: '空白（页宽+居中页码）',
      type: 'background-page_nubmer',
      img: require('./img/页宽+居中页码.jpg'),
      data: {
        relative_margin: '0mm',
        offset: calcFontHeight(default_style),
        margin_before: '0mm',
        data: [

          {
            node_id: setId(),
            node_type: 'image',
            content: {
              url: ''
            },
            margin: {
              before: '0mm',
            },
            img_size: {
              width: page_core_size.width,
              height: page_margin.bottom
            },
          },
          {
            node_id: setId(),
            node_type: 'page_number',
            content: {
              text: ''
            },
            position: {
              'x': (page_core_size.width) / 2 - 2,
              'y': -(page_core_size.height)
            },
            style: getStyle()
          }
        ]
      }
    },
    {
      name: '空白（底图+文字+页码）',
      type: 'background-page_nubmer-text',
      img: require('./img/空白（底图+文字+页码）.jpg'),
      data: {
        relative_margin: '0mm',
        offset: calcFontHeight(default_style),
        margin_before: '0mm',
        data: [
          {
            node_id: setId(),
            node_type: 'image',
            content: {
              url: ''
            },
            margin: {
              before: '0mm',
            },
            img_size: {
              width: page_size.width,
              height: page_margin.bottom
            },
          },
          {
            node_id: setId(),
            node_type: 'page_number',
            content: {
              text: ''
            },
            position: {
              'x': (page_core_size.width) - 2,
              'y': -(page_core_size.height)
            },
            style: getStyle()
          },
          {
            node_id: setId(),
            node_type: 'text',
            content: {
              text: '',
              align: 'left'
            },
            margin: {
              before: '0mm'
            },
            style: getStyle()
          }
        ]
      }
    },
  ];
};
